/**
 * Beligent
 * Copyright 2023
 */

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Turns URL path into router basename by removing everything after the last slash
 * @param {string} path URL path, probably window.location.pathname
 * @returns {string} final basename
 */
const getBasename = (path) => path.substr(0, path.lastIndexOf("/"));
// Could use in other pages, like for the routes on App.js:
// export const BASENAME = getBasename(window.location.pathname);
// or
// export const BASENAME = (path) => path.substr(0, path.lastIndexOf("/"));
// and
// import { BASENAME } from './index.js';
// {BASENAME}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="">
    {/* <BrowserRouter basename="/testenv/"> */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
