/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React from "react";
import "./AppFooter.scss";

const AppFooter = () => {
    return (
        <footer className="AppFooter">
            <div className="menu-container">
                <strong>Contact Us:</strong>
                <br />
                contact(at)beligent.io
                {/* <ul className="menu">
                        <span>
                            <strong>Menu</strong>
                        </span>
                        <li>
                            <a href="#" target="#">
                                Solutions
                            </a>
                        </li>
                        <li>
                            <a href="#" target="#">
                                Case Study
                            </a>
                        </li>
                        <li>
                            <a href="#" target="#">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="#" target="#">
                                Contact us
                            </a>
                        </li>
                    </ul> */}
            </div>
            <div className="logo-section">
                <p>
                    <strong>Beligent </strong> ©2023
                    <br />
                    Zurich, Switzerland
                </p>
                <div className="logo-container">
                    <img
                        src="logo-small.png"
                        className="img-fluid "
                        alt="Beligent"
                    />
                </div>
            </div>
            {/* </div> */}
        </footer>
    );
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
