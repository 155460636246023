/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import axios from "axios";
import "./LoginPopup.scss";

const LoginPopup = ({ closePopup, recordUser }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [serverError, setServerError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    // to LoginForm
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    const startSessionTimer = () => {
        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds
        setTimeout(() => {
            localStorage.removeItem("user");
            localStorage.removeItem("userTimestamp");
            console.log("Session expired after 10 minutes");
        }, TEN_MINUTES);
    };
    /*
    // to LoginForm
    const handleLogin = async () => {
        setServerError(""); // Clear previous errors
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email.");
            return;
        } else {
            setEmailError(""); // Clear email error
        }

        // Simulate API call to login
        setIsSubmitting(true);
        try {
            // Mocking an API request
            const response = await Login(email, password); // Replace with real API call
            if (response.success) {
                const user = { name: response.name };
                recordUser(user);
                startSessionTimer();
                closePopup();
            } else {
                setServerError("Invalid email or password.");
            }
        } catch (error) {
            console.error(error);
            setServerError("An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };
*/
    // Mock function to simulate an API call
    // To LoginForm
    const mockLoginApi = (email, password) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (
                    email === "test@example.com" &&
                    password === "password123"
                ) {
                    resolve({ success: true, name: "David" });
                } else {
                    resolve({ success: false });
                }
            }, 1000);
        });
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === "popup-overlay") {
            closePopup();
        }
    };

    //     console.log("Login function called with email:", providedEmail);
    //     // State variables for form inputs and handling state
    //     const [email, setEmail] = useState(providedEmail);
    //     const [password, setPassword] = useState(providedPassword);

    // State for handling loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Handler for form submission
    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true); // Set loading state to true
        setError(null); // Reset error state

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email.");
            return;
        } else {
            setEmailError(""); // Clear email error
        }

        try {
            // Make a POST request to the backend /login endpoint
            const response = await axios.post("http://localhost:3001/login", {
                email,
                password,
            });

            console.log("Login successful:", response);
            // Assuming the backend sends a JWT token on successful login
            const { user } = response.data;
            console.log("user:", user);

            // Store the token in localStorage (or any other storage mechanism)
            localStorage.setItem("token", user.token);

            recordUser(user);

            closePopup();

            // Redirect
            window.location.href = "/platform";
        } catch (err) {
            // Handle errors
            if (
                err.response &&
                err.response.data &&
                err.response.data.message
            ) {
                setError(err.response.data.message);
            } else {
                setError("An unexpected error occurred.");
            }
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    return (
        <div className="LoginPopup" onClick={handleOverlayClick}>
            <div
                className="LoginPopup popup-overlay"
                onClick={handleOverlayClick}
            >
                {/* ---------------------------------------- */}
                {/* ---------------------------------------- */}
                {/* ---------------------------------------- */}
                {/*
                    This need to be a component LoginForm 
                    LoginForm will also be used in the middle of the platform main page
                */}
                <div className="popup">
                    <button className="close-btn" onClick={closePopup}>
                        X
                    </button>
                    <h2>Login</h2>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {emailError && <p className="error">{emailError}</p>}
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={handleLogin} disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Login"}
                    </button>
                    {serverError && <p className="error">{serverError}</p>}
                </div>

                {/* ---------------------------------------- */}
                {/* ---------------------------------------- */}
                {/* ---------------------------------------- */}
            </div>
        </div>
    );
};

LoginPopup.propTypes = {};

LoginPopup.defaultProps = {};

export default LoginPopup;
