/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";
import "./Platform.scss";
// import Minting from "./../../Minting/Minting.tsx";

import CertificateMaker from "./../../CertificateMaker/CertificateMaker.tsx";

const Platform = ({ user }) => {
    const userConnected = user && user?.publicAddress !== "";
    console.log("platform Component");
    console.log(user);

    const [tokenId, setTokenId] = useState("");
    const [collectionId, setCollectionId] = useState("");

    // local state is initialized by local storage
    // useless
    // const [connectedToMintingContract, setConnectedToMintingContract] =
    //     useState(() => {
    //         const saved = localStorage.getItem(
    //             "_beligent_connectedToMintingContract"
    //         );
    //         return saved ? JSON.parse(saved) : false;
    //     });
    // // Local storage updates when component state is updated
    // useEffect(() => {
    //     localStorage.setItem(
    //         "_beligent_connectedToMintingContract",
    //         JSON.stringify(connectedToMintingContract)
    //     );
    // }, [connectedToMintingContract]);

    // local state is initialized by local storage
    const [minted, setMinted] = useState(() => {
        const saved = localStorage.getItem("_beligent_minted");
        return saved ? JSON.parse(saved) : false;
    });
    // Local storage updates when component state is updated
    useEffect(() => {
        localStorage.setItem("_beligent_minted", JSON.stringify(minted));
    }, [minted]);

    /* <h1>Welcome to Beligent's website</h1> */
    const renderDefaultMessage = () => (
        <div className="welcome-message">
            <h3 className="">Welcome to Beligent's portal</h3>
        </div>
    );

    const renderWelcomeMessage = () => (
        <div className="">
            <br />
            <br />
            <h3 className="">Certificate platform</h3>
            <br />
            <p>Welcome {user.firstName}</p>
            <br />
        </div>
    );

    const renderCertificateMakerComponent = () => (
        <CertificateMaker
            nftMinted={minted}
            user={user}
            tokenId={tokenId}
            collectionId={collectionId}
        />
    );

    const onMintedCallback = (collectionId: string, tokenId: string) => {
        console.log("onMintedCallback");
        console.log("tokenId", tokenId);
        console.log("collectionId", collectionId);
        setMinted(true);
        setTokenId(tokenId);
        setCollectionId(collectionId);
        console.log(user?.publicAddress);
    };

    /* NOT SURE THIS IS NEEDED */
    useEffect(() => {
        const { ethereum } = window;

        if (!ethereum) {
            console.log("Make sure you have metamask!");
            return;
        } else {
            // console.log("We have the ethereum object", ethereum);
        }
    }, []);

    return (
        <div className="Platform">
            {(() => {
                if (userConnected) {
                    return (
                        <div>
                            {renderWelcomeMessage()}
                            {/* {renderMintingComponent()}
                            {renderCertificateMakerComponent()} */}
                        </div>
                    );
                } else {
                    return <div>{renderDefaultMessage()}</div>;
                }
            })()}
        </div>
    );
};

Platform.propTypes = {};

Platform.defaultProps = {};

export default Platform;
