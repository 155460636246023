/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AppHeader.scss";

import WalletInfo from "./../WalletInfo/WalletInfo.js";
import LoginButton from "../Login/LoginButton/LoginButton";
// import { isSepoliaNetworkConnected } from "../../utils";

const AppHeader = ({ user, recordUser }) => {
    // const [user, setUser] = useState(null);

    // const handleLogin = (loggedInUser) => {
    //     // Callback for successful login from popup
    //     setUser(loggedInUser);
    //     setIsPopupOpen(false); // Close the popup after login
    //   };

    return (
        <div className="AppHeader">
            <header>
                <div>
                    <div className="logo-container">
                        <Link to="./">
                            <img
                                src="logo.png"
                                className="app-logo img-fluid"
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand-lg">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarNavAltMarkup"
                        >
                            <div className="navbar-nav">
                                {/* <a
                                    className="nav-item nav-link active"
                                    href="#"
                                >
                                    Home
                                </a>
                                <a
                                    className="nav-item nav-link active"
                                    href="#"
                                >
                                    {" "}
                                    -- Website under construction --
                                </a> */}

                                {/* <a className="nav-item nav-link active" href="#">
                                    Solutions
                                </a>
                                <a className="nav-item nav-link active" href="#">
                                    Case study
                                </a>
                                <a className="nav-item nav-link active" href="#">
                                    About
                                </a>
                                <a
                                    className="nav-item nav-link active"
                                    href="#contact"
                                >
                                    Contact us
                                </a> */}
                            </div>
                        </div>
                    </nav>
                    <LoginButton
                        user={user}
                        recordUser={recordUser}
                    ></LoginButton>
                </div>
            </header>

            {/* {(() => {
                if (user.publicAddress) {
                    return <WalletInfo user={user} />;
                }
            })()} */}
        </div>
    );
};

AppHeader.propTypes = {};

AppHeader.defaultProps = {};

export default AppHeader;
