/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useState } from "react";
import "./LoginButton.scss";

import LoginPopup from "../LoginPopup/LoginPopup";

const LoginButton = ({ user, recordUser }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        if (!user) {
            setIsPopupOpen(!isPopupOpen);
        } else {
            // Todo: Logout
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("userTimestamp");
        recordUser(null);
      };
    
    return (
        <div className="LoginButton">
            <div className="block-center">
                <div className="">
                    {user ? (
                        <div>
                            <button
                                onClick={handleLogout}
                                className="btn action-button"
                            >
                                Logout
                            </button>
                        </div>
                    ) : (
                        <div>
                            <button
                                onClick={togglePopup}
                                className="btn action-button"
                            >
                                Login
                            </button>
                        </div>
                    )}

                    {/* <button
                        type="button"
                        onClick={togglePopup}
                        className="btn action-button"
                    >
                        {user ? "Logout" : "Login"}
                    </button> */}
                    {isPopupOpen && (
                        <LoginPopup
                            closePopup={togglePopup}
                            recordUser={recordUser}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

LoginButton.propTypes = {};

LoginButton.defaultProps = {};

export default LoginButton;
