/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2023
 */

import React, { useEffect, useState } from "react";

import "./CheckApp.scss";
import Display from "../Display/Display.tsx";
// To get NFT data
import { Network, Alchemy } from "alchemy-sdk";
// To verify Beligent certificates

import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import abiCertificateMaker from "../../../ABI/RoaManagerV1.json";

import {
    isIpfsUrl,
    isSvg,
    IPFS_PROTOCOL_PREFIX,
} from "../../../utils.js";

const HTTPS_PROTOCOL_PREFIX = "https://";
const publicKeyVitalik = "0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045";

const CheckApp = ({ user }) => {
    console.log("user: ", user);

    const location = useLocation();

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    const addressParameter = params.get("address");
    console.log(addressParameter);

    console.log(params.toString().split("=")[1]);
    console.log(urlSearchString);
    // const [inputUrl, setInputUrl] = useState<string | undefined>();
    const [resultObject, setResultObject] = useState(null);
    // const [certificateData, setCertificateData] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);

    let svgData = "";
    let imageUrl = "";
    let imageIsSvg = false;
    // let untestedUrl = "";

    let smartContractAddress = "";
    let network = "";
    let tokenId = "";

    const certificateContractAbi = abiCertificateMaker.abi;
    let certificateContract;
    let provider;
    let signer;

    function isValidUrl(url: string | undefined) {
        return url ? url.startsWith(HTTPS_PROTOCOL_PREFIX) : false;
    }

    function isOpenSeaUrl(url: string | undefined) {
        let urlFragments: any[];
        if (!url) {
            return false;
        }
        try {
            urlFragments = url.split("/");
            return urlFragments[2] === "opensea.io";
        } catch (error) {
            return false;
        }
    }

    function isOpenSeaTestnetUrl(url: string | undefined) {
        let urlFragments: any[];
        if (!url) {
            return false;
        }
        try {
            urlFragments = url.split("/");
            return urlFragments[2] === "testnets.opensea.io";
        } catch (error) {
            return false;
        }
    }

    function ipfsToHttpUrl(ipfsUrl: string) {
        if (!isIpfsUrl(ipfsUrl)) {
            console.log("[ERROR]: Not an IPFS URL: " + ipfsUrl);
            return "";
        }
        return "https://ipfs.io/ipfs/" + ipfsUrl.split(IPFS_PROTOCOL_PREFIX)[1];
    }

    /*
     * Alchemy
     */
    const alchemyRequest = async (
        network,
        smartContractAddress,
        tokenId
    ): Promise<any> => {
        try {
            console.log("alchemyRequest");

            // TODO better validation
            if (!smartContractAddress || !tokenId) {
                return;
            }
            console.log(network);
            const settings = {
                apiKey: process.env
                .REACT_APP_ALCHEMY_API_KEY_POLYGON_AMOY_CHECK_APP_BACKUP,
                    // .REACT_APP_ALCHEMY_API_KEY_POLYGON_AMOY_CHECK_APP,
                network: network,
            };
            const alchemy = new Alchemy(settings);
            return alchemy.nft
                .getNftMetadata(smartContractAddress, tokenId)
                .then((response: any) => {
                    console.log(" -- REQUEST RESPONSE --");
                    console.log(response);
                    let metadataImage = "";
                    if (response.raw.metadata.image) {
                        // if (response.rawMetadata.image) {
                        metadataImage = response.raw.metadata.image;
                    } else if (
                        response.media &&
                        response.media[0] &&
                        response.media[0].gateway
                    ) {
                        // Cryptokitty type of NFT
                        metadataImage = response.media[0].gateway;
                    } else if (response.rawMetadata.image_url) {
                        metadataImage = response.rawMetadata.image_url;
                    } else {
                        console.log(response);
                        console.log("[ERROR]: Image URL not found in metadata");
                        // TODO reject promise
                    }

                    imageIsSvg = false;
                    if (isSvg(metadataImage)) {
                        imageIsSvg = true;
                        console.log("isSvg");
                        const svgSplit = metadataImage.split(
                            "data:image/svg+xml;utf8,"
                        );
                        console.log(svgSplit);
                        if (svgSplit.length > 1) {
                            svgData = svgSplit[1];
                        } else {
                            svgData = svgSplit[0];
                        }
                    } else if (isIpfsUrl(metadataImage)) {
                        console.log("isIpfsUrl");
                        imageUrl = ipfsToHttpUrl(metadataImage);
                    } else {
                        console.log("Image not on IPFS");
                        imageUrl = metadataImage;
                        svgData = metadataImage.split(
                            "data:image/svg+xml;utf8,"
                        )[1];
                    }

                    return {
                        imageUrl: imageUrl,
                        svgData: svgData,
                        isSvg: imageIsSvg,
                        alchemyResponse: response,
                    };
                });
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Extracts first information from URL
     */
    function transformUrl(url: string | undefined) {
        if (!url) {
            return {
                network: "",
                smartContractAddress: "",
                tokenId: "",
            };
        }
        if (isValidUrl(url)) {
            if (isOpenSeaUrl(url)) {
                const urlFragments = url.split("/");

                // if (urlFragments[4] !== "ethereum") {
                //     throw "[ERROR] This is not an ethereum asset";
                // } else {
                //     network = Network.ETH_MAINNET;
                // }
                console.log(urlFragments[4]);
                network = "Polygon"; // temp

                smartContractAddress = urlFragments[5];
                tokenId = urlFragments[6];
            } else if (isOpenSeaTestnetUrl(url)) {
                const urlFragments = url.split("/");

                // if (urlFragments[4] !== "sepolia") {
                //     throw "[ERROR] This is not an ethereum asset";
                // } else {
                //     network = Network.ETH_GOERLI;
                // }
                network = Network.MATIC_AMOY; //"Polygon"; // temp

                smartContractAddress = urlFragments[5];
                tokenId = urlFragments[6];
            } else {
                console.log("Not recognized URL");
            }
        }
        return {
            network: network,
            smartContractAddress: smartContractAddress,
            tokenId: tokenId,
        };
    }

    async function callBeligentSmartContract(
        collectionAddress: string,
        tokenId: string
    ): Promise<any> {
        if (ethereum) {
            console.log("Waiting for certificate");

            provider = new ethers.providers.Web3Provider(ethereum);
            signer = provider.getSigner();

            certificateContract = new ethers.Contract(
                process.env.REACT_APP___DEPRECATED__CERTIFICATE_CONTRACT_ADDRESS!,
                certificateContractAbi,
                signer
            );

            const resultSmartContractQuery =
                await certificateContract.getCertificate(
                    collectionAddress,
                    tokenId
                );

            // console.log(resultSmartContractQuery);
            // const resultSmartContractQuery2 = await resultSmartContractQuery
            return resultSmartContractQuery;
            // certificateContract.getCertificate(
            //     collectionAddress,
            //     tokenId
            // );
        } else {
            console.log(
                "Ethereum object for metamask missing to call the smart contract."
            );
        }
    }

    const onSubmitCallback = (openSeaUrl: string | undefined) => {
        console.log("validator onSubmitCallback", openSeaUrl);
        const queryInput = transformUrl(openSeaUrl);

        alchemyRequest(
            queryInput.network,
            queryInput.smartContractAddress,
            queryInput.tokenId
        )
            .then((alchemyResponse: any) => {
                console.log("alchemyResponse");
                console.log(alchemyResponse);

                // We call our smart contract if metadata displays our information
                if (
                    alchemyResponse &&
                    Object.keys(alchemyResponse).length > 0
                ) {
                    if (
                        alchemyResponse.alchemyResponse.raw.metadata
                            .certificate &&
                        alchemyResponse.alchemyResponse.raw.metadata.certificate
                            .issuer === "Beligent"
                    ) {
                        // call Beligent smart contract and check with collection and tokenId
                        console.log("callBeligentSmartContract");
                        console.log(
                            alchemyResponse.alchemyResponse.contract.address
                        );
                        console.log(alchemyResponse.alchemyResponse.tokenId);
                        console.log(alchemyResponse.alchemyResponse);
                        callBeligentSmartContract(
                            alchemyResponse.alchemyResponse.contract.address,
                            alchemyResponse.alchemyResponse.tokenId
                        ).then((beligentResponse: any) => {
                            console.log(beligentResponse);
                            alchemyResponse["certificateData"] = {};
                            alchemyResponse[
                                "certificateData"
                            ].nftCreatorAddress =
                                beligentResponse.nftCreatorAddress;
                            alchemyResponse["certificateData"].nftCreatorName =
                                beligentResponse.nftCreatorName;
                            alchemyResponse["certificateData"].timestamp =
                                beligentResponse.timestamp;

                            let issueDate = "";
                            if (
                                alchemyResponse["certificateData"] &&
                                alchemyResponse["certificateData"].timestamp
                            ) {
                                issueDate = new Date(
                                    alchemyResponse[
                                        "certificateData"
                                    ].timestamp.toNumber() * 1000
                                ).toString();
                            }
                            alchemyResponse["certificateData"].issueDate =
                                issueDate;

                            console.log(alchemyResponse);
                            setResultObject(alchemyResponse);
                        });
                    } else {
                        console.log(alchemyResponse);
                        setResultObject(alchemyResponse);
                    }
                }
            })
            .catch((error: Error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        onSubmitCallback(
            // "https://opensea.io/assets/ethereum/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/9998"
            //"https://opensea.io/assets/ethereum/0xab59ec5549b20468fd21d4b64f93b7c9437e6ebe/3" // Vountain NFT
            "https://testnets.opensea.io/assets/amoy/0x68e7f29a8ac8a8dcb5e3c5f692ca291d2f65e386/0" // Beligent NFT
        );
    }, []);

    /* <h1>Welcome to Beligent's website</h1> */
    // TODO better naming
    const renderWelcomeMessage = () => (
        <div className="welcome-message">
            <h1 className="center">
                Welcome to Beligent's website
            </h1>
            <p>The page is not found</p>
        </div>
    );

    const renderNotConnectedContainer = () => (
        <div className="welcome-message">
            <p>Please connect your wallet.</p>
        </div>
    );

    return (
        <>
            {(() => {
                return (
                    <div className="CheckApp">
                        <div className="report core-width">
                            {loading && (
                                <span className="center">Please wait...</span>
                            )}

                            {!loading &&
                            resultObject &&
                            addressParameter === publicKeyVitalik &&
                            Object.keys(resultObject).length !== 0 ? (
                                <Display resultObject={resultObject} />
                            ) : (
                                // <span></span>

                                /*

{ 
{urlParam ? (
                                  // If URL parameter exists
                                  <Display  resultObject={resultObject} />
                                ) */
                                // Default message when no parameters

                                <p className="center">
                                    Demo ended. Please provide a valid
                                    parameter.
                                </p>
                            )}
                        </div>
                    </div>
                );
            })()}
        </>
    );
};

CheckApp.propTypes = {};

CheckApp.defaultProps = {};

export default CheckApp;
